@font-face {
  font-family: 'DM Sans', sans-serif;
  src: url('assets/fonts/DM_Sans/DMSans-Bold.ttf'),
       url('assets/fonts/DM_Sans/DMSans-BoldItalic.ttf'),
       url('assets/fonts/DM_Sans/DMSans-Italic.ttf'),
       url('assets/fonts/DM_Sans/DMSans-Medium.ttf'),
       url('assets/fonts/DM_Sans/DMSans-MediumItalic.ttf'),
       url('assets/fonts/DM_Sans/DMSans-Regular.ttf'),
      ;
}

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DM Sans', sans-serif;
}
iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  pointer-events: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #272931 inset !important;
    border-radius: 15px 0 0 15px;
    border: none;
}

input:-webkit-autofill{
  -webkit-text-fill-color: #C1C3CB !important;
}

::-webkit-scrollbar {
  width: 1px;
}